import React, { useEffect } from "react"

export default () => {
  useEffect(() => {
    window.location.href =
      "https://docs.partnr.ai/docs/apis/Insider-API.yaml"
  }, [])

  return <div></div>
}
